.confirmation-header {
    font-family: system-ui;
    font-style: normal;
    font-weight: 500;
    font-size: 24.4px;
    line-height: 32px;
    text-align: center;
    letter-spacing: -0.3px;
    color: #0f191a;
}

.confirmation-para-content {
    font-family: system-ui;
  font-style: normal;
  font-weight: 400;
  font-size: 19.4px;
  line-height: 25px;
  text-align: center;
  letter-spacing: -0.3px;
  color: rgba(15, 25, 26, 0.5);
}

.confirmation-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
}

.confirmation-subcontainer {
    margin-top: 1.5rem;
}

.confirmation-btn-container {
    width: 90%;
    margin-bottom: 1rem;
}