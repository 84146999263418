/** @format */

.cart-screen-item-container {
   border: 1.5px solid rgba(249, 202, 65, 0.25);
   border-radius: 8px;
   display: flex;
   align-items: center;
   margin: 1rem 0.75rem;
   padding: 0.5rem;
}

.cart-screen-image-container {
   height: 4rem;
   width: 4rem;
   border-radius: 6.07418px;
   border: 1px solid gray;
   margin: 0 0.75rem;
}

.cart-screen-text-container {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   gap: 0.6rem;
}

.cart-screen-name-container {
   font-family: system-ui;
   font-style: normal;
   font-weight: 700;
   font-size: 15.6px;
   line-height: 20px;
   letter-spacing: -0.3px;
   color: #0f191a;
}

.cart-screen-quantity-container {
   font-family: system-ui;
   font-style: normal;
   font-weight: 400;
   font-size: 15.6px;
   line-height: 20px;
   letter-spacing: -0.3px;
   color: rgba(15, 25, 26, 0.5);
}

.cart-screen-modal-container {
   display: flex;
   flex-direction: column;

   justify-content: space-between;
   padding: 1rem;
   height: 85%;
   justify-content: space-between;

   justify-content: space-between;
   padding: 1rem;
}

.cart-screen-modal-header-text {
   font-family: system-ui;
   font-style: normal;
   font-weight: 700;
   font-size: 15.6px;
   line-height: 20px;
   text-align: center;
   letter-spacing: -0.3px;
   color: #0f6656;
}

.cart-screen-content-text {
   font-family: system-ui;
   font-style: normal;
   font-weight: 500;
   font-size: 19.4px;
   line-height: 25px;
   letter-spacing: -0.3px;
   color: #0f191a;
}

.cart-screen-content-text2 {
   font-family: system-ui;
   font-style: normal;
   font-weight: 400;
   font-size: 15.6px;
   line-height: 20px;
   text-align: right;
   letter-spacing: -0.3px;
   color: #4361ee;
}

.cart-screen-content-text3 {
   font-family: system-ui;
   font-style: normal;
   font-weight: 400;
   font-size: 12.5px;
   line-height: 16px;
   letter-spacing: -0.3px;
   color: #0f6656;
   display: flex;
   align-items: center;
}

.cart-screen-payment-type-modal-content {
   font-family: system-ui;
   font-style: normal;
   font-weight: 400;
   font-size: 24.4px;
   line-height: 32px;
   text-align: center;
   letter-spacing: -0.3px;
   color: #000000;
   margin-left: 1rem;
}

.cart-screen-payment-type-modal-header {
   font-family: system-ui;
   font-style: normal;
   font-weight: 700;
   font-size: 24.4px;
   line-height: 32px;
   display: flex;
   align-items: center;
   text-align: center;
   letter-spacing: -0.3px;
   color: #000000;
   margin: 0 auto;
}

.cart-screen-confirmation-text {
   font-family: system-ui;
   font-style: normal;
   font-weight: 400;
   font-size: 24.4px;
   line-height: 32px;
   text-align: center;
   letter-spacing: -0.3px;
   color: rgba(15, 25, 26, 0.5);
   margin: 1rem;
}

.cart-screen-item-details {
   width: calc(100% - 6rem);
   font-size: 1.25rem;
   color: #0f191a;
   line-height: 1.33;
   text-align: center;
   font-weight: 700;
}

.cart-screen-btns-container {
   display: flex;
   justify-content: space-between;
   align-items: center;
   gap: 1rem;
   border-radius: 8px;
   background: #f9ca411a;
   margin-top: 0.5rem;
}

.cart-screen-qty-btns {
   padding: 0.5rem;

   background: #f9ca41;
   border: none;
   border-radius: 10px;
}

.cart-screen-qty-btns > svg {
   height: 1.5rem;
   width: 1.5rem;
}

.cart-screen-qty-text {
   font-size: 1rem;
   font-weight: 700;
   line-height: 1.33;
}
