/** @format */

.warning-container {
   width: 100%;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
}

.warning-modal-container {
   background-color: #fff;
   padding: 20px;
   width: 40%;
   height: 40%;
   border-radius: 10px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}

.warning-modal-text {
   width: 80%;
   font-family: DM Sans;
   font-size: 2rem;
   text-align: center;
   line-height: 3rem;
   color: #0f6656;
   font-weight: bold;
}
