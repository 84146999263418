/** @format */

.also-ordered-container {
   background: #ffffff;
   border: 1px solid rgba(15, 25, 26, 0.25);
   border-radius: 12px;
   height: 9rem;
   width: 50%;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: center;
}

.also-ordered-img {
   border-radius: 10px 10px 0px 0px;
   margin-top: 2%;
}

.also-ordered-item-name {
   font-family: "DM Sans";
   font-style: normal;
   font-weight: 500;
   font-size: 15.6px;
   line-height: 20px;
   letter-spacing: -0.3px;
   color: #0f191a;
   margin-left: 0.25rem;
}

.also-ordered-middle-container {
   display: flex;
   justify-content: space-between;
   width: 100%;
}

.also-ordered-price-container {
   background: #0f6656;
   border-radius: 14px 0px 0px 14px;
   padding: 0.2rem 0.5rem;
}

.also-ordered-price-text-container {
   font-family: "DM Sans";
   font-style: normal;
   font-weight: 700;
   font-size: 15.6px;
   line-height: 20px;
   text-align: right;
   letter-spacing: -0.3px;
   color: #ffffff;
}

.also-ordered-icon-container {
   display: flex;
   margin-left: 0.25rem;
   align-items: center;
}

.also-ordered-btns-container {
   display: flex;
   justify-content: center;
   align-items: center;
}

.also-ordered-btns-container-count-content {
   font-family: "DM Sans";
   font-style: normal;
   font-weight: 700;
   font-size: 19.4px;
   line-height: 25px;
   text-align: center;
   letter-spacing: -0.3px;
   color: #0f191a;
   margin: 0 0.5rem;
}
