/** @format */

.single-order-details-container {
   background: #fcfeff;
   min-height: calc(100vh - 8rem);
   margin-bottom: 8rem;
}

.single-order-details-divider {
   height: 1px;
   width: 100%;
   background-color: rgba(15, 25, 26, 0.1);

   margin: 1.5rem 0;
}

.single-order-details-orders-container {
   display: flex;
   flex-direction: column;
   gap: 0.5rem;
}

.single-order-details-bottom-btn-container {
   position: fixed;
   bottom: 3rem;
   width: 90%;
   left: 5%;
}

.single-order-details-list {
   display: flex;
   flex-direction: column;
   gap: 0.5rem;
}

.single-order-details-info-container {
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   margin: 0.5rem 1rem 0 1rem;
}

.single-order-details-bottom-container {
   position: fixed;
   bottom: 2;
   width: 100%;
   height: auto;
   background: #fff;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   padding-top: 0.5rem;
}

.single-order-details-info-div-svg-text {
   font-family: DM Sans;
}

.single-order-details-list-unavailable-text {
   font-size: 1rem;
   font-weight: 700;
   color: #d90429;
   line-height: 1rem;
   letter-spacing: -0.3px;
   margin-left: 2rem;
   margin-top: 1rem;
}

.single-order-details-list-available-text {
   font-size: 1.2rem;
   font-weight: 700;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   padding-right: 5%;
   color: #0f191a;
   line-height: 1rem;
   margin: 0 0 1rem 2rem;
   letter-spacing: -0.3px;
   font-family: DM Sans;
   height: max-content;
   padding: 1%;
   padding-right: 5%;
}

.order-time {
   font-size: 16px;
   font-weight: 500;

   color: #868b8c;
   padding-inline-start: 5px;
}

.order-no {
   display: flex;
}

.order-status-header-text-container {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: center;
   margin: 0.5rem 1rem 0 1rem;
}

.view-order-status-container {
   display: flex;
   flex-direction: row;
   gap: 0.5rem;
   margin-top: 0.5rem;
}

.view-order-status-text {
   font-size: 1rem;
   text-decoration-line: underline;
}

.active-cards-single-order-page-container {
   overflow-y: scroll;
   display: flex;
   flex-direction: column;
   align-items: center;

   &::-webkit-scrollbar {
      width: 0.3rem;
   }

   &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
      border-radius: 10px;
      margin-right: 0.3rem;
   }

   &::-webkit-scrollbar-thumb {
      background: black;
      border-radius: 0.2rem;
   }

   ::-webkit-scrollbar-thumb:hover {
      background: #b30000;
   }
}

.single-order-details-info-container-svg-text {
   font-size: 1rem;
   font-weight: 400;
   color: #0f6656;
   line-height: 1.3rem;
   margin: 0 0 0 1rem;
   letter-spacing: -0.3px;
}

.single-order-details-border {
   height: 1px;
   width: 100%;
   background-color: rgba(15, 25, 26, 0.1);
   margin: 1rem 0;
}

.single-order-details-bottom-container-btn-add-item {
   margin-bottom: 3rem;
   width: 85%;
   background: #0f6656;
   color: #fff;
   height: 4rem;
   width: 90%;
   display: flex;
   align-items: center;
   justify-content: center;
   font-family: "DM Sans Bold", sans-serif;
   flex-direction: row;
   margin: 0 auto;
   cursor: pointer;
   margin-top: 1rem;
   border-radius: 0.75rem;
   font-size: 1.5rem;
   border: none;
}

.single-order-details-bottom-container-btn-go-to-menu {
   background: #0f6656;
   color: #fff;
   text-align: center;
   align-self: center;
   font-size: 1.2rem;
   border-radius: 8px;
   font-family: DM Sans;
   padding: 1rem;
}

.single-order-page-button-wrapper {
   background-color: #ffffff;
   position: fixed;
   bottom: 0rem;
   left: 0rem;
   right: 0rem;
   padding: 0.5rem;
}

.single-order-details-proceed-without-add-items-text {
   font-size: 1.3rem;
   font-weight: 500;
   color: #4361ee;
   line-height: 1.5rem;
   text-align: center;
   position: fixed;
   letter-spacing: -0.3px;
   bottom: 1rem;
   text-decoration-line: underline;
}

.add-more-items-text {
   color: #4361ee;
   font-size: DM Sans;
   text-align: center;

   font-size: 1.3rem;
   font-weight: bold;
   margin-right: 0.3rem;
}

.single-order-details-skeleton {
   margin-top: 5rem;
}

.single-order-details-skeleton-container {
   display: flex;
   flex-direction: column;
   margin: 1rem 1.5rem 0 1.5rem;
   width: 90%;
   justify-content: center;
}

.single-order-details-skeleton-subcontainer {
   display: grid;
   grid-template-columns: 1fr 4fr;
   align-items: center;
}

.order-status-header {
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 1rem 1.5rem;
   position: fixed;
   right: 0;
   left: 0;
   z-index: 1000;
}

.sub-order-status-header {
   display: flex;
   align-items: baseline;
   padding: 1rem 1.5rem;
   right: 0;
   left: 0;
   z-index: 1000;
   flex-direction: row;
   background-color: white;
   justify-content: space-between;
}

.order-status-header-text {
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   margin-left: 1.8rem;
}

.order-id {
   color: #868b8c;
   // background-color: red;
   text-align: center;
}

.order-status-header-order {
   font-size: 1.1rem;
   font-weight: 700;
   color: #0f191a;
   line-height: 1.5rem;
   letter-spacing: -0.3px;
   font-family: DM Sans;
}

.dine-in-order-status-header-order {
   font-size: 1.4rem;
   font-weight: 700;
   color: #0f191a;
   line-height: 1.5rem;
   letter-spacing: -0.3px;
   font-family: DM Sans;
   width: 100%;
   text-align: center;
   white-space: nowrap;
}

.order-status-main-container {
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   gap: 0.5rem;
   padding-top: 20%;
}

.order-status-main-container-dine-in {
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   gap: 0.5rem;
   padding: 3%;
}

.order-status-main-container-top {
   display: flex;
   justify-content: center;
   flex-direction: column;
   width: 85%;
   background-color: #fff;
   box-shadow: 0px 2px 10px rgba(25, 57, 72, 0.101);
   border-radius: 10px;
   // margin-bottom: 4%;
}

.order-status-main-container-bottom {
   width: 85%;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   gap: 1rem;
   position: fixed;
   bottom: 0;
   z-index: 11111;
   padding-bottom: 4%;
   background-color: #fff;
}

.extra {
   width: 2rem;
}

.order-status-head {
   font-size: 14px;
   font-weight: 600;
   color: #000;
   line-height: 1.5rem;
   letter-spacing: -0.3px;
   font-family: DM Sans;
   padding: 2% 3% 2% 3%;
}

.main-order-status {
   display: flex;
   flex-direction: column;
   text-align: center;
   margin-left: -7%;
   font-size: 1.2rem;
   font-family: DM Sans;
   font-weight: 900;
   padding: 5%;
}

.order-details-divider {
   border: 1px solid rgba(15, 25, 26, 0.1);
   position: relative;
   margin-bottom: 0.5rem;
}

.stepper-wrapper {
   display: flex;
   flex-direction: column;
   padding: 2% 4% 2% 4%;
}

.order-status-head-title {
   display: flex;
   align-items: center;
   justify-content: space-between;
   font-size: 0.9rem;
}

.order-status-head-title-text {
   color: #878c8c;
   font-weight: 500;
}

.order-status-head-title-content {
   color: #000;
}

.order-status-stepper-title {
   font-family: "DM Sans";
   font-style: normal;
   font-weight: 500;
   font-size: 12.5px;
   line-height: 16px;
   letter-spacing: 1px;
   color: #878c8c;
}

.view-bill-btn {
   font-family: DM Sans;
   color: #4361ee;
   display: flex;
   align-items: center;
   justify-content: center;
   gap: 0.5rem;
   width: fit-content;
   white-space: nowrap;
   margin-left: 4%;
}
