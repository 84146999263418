/** @format */

.no-items-container {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   margin-top: 20%;
}

.no-items-wrapper {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
}

.no-items {
   color: #0f6656;
   font-family: DM Sans;
   margin-top: 2rem;
   font-size: 1.5rem;
}
