.order-summary-page-container {
  height: 100vh;
}

.order-summary-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1.5rem;
  background: linear-gradient(90deg, #ffb356 -25%, #fff95b 106.97%);
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1000;
}

.order-summary-header-text {
  font-family: DM Sans;
  font-size: 1.5rem;
  font-weight: 700;
}

.order-summary-items-container {
  // background-color: red;

  overflow-y: scroll;
  margin-top: 1rem;
  display: inline-block;
  width: 100%;
  padding-bottom: 7rem;

  &::-webkit-scrollbar {
    width: 0.3rem;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
    margin-right: 0.3rem;
  }

  &::-webkit-scrollbar-thumb {
    background: black;
    border-radius: 0.2rem;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #b30000;
  }
}

.order-summary-button {
  position: fixed;
  width: 90%;
  bottom: 0.5rem;
  right: 5%;
  left: 5%;
  // background-color: red;
  // display: flex;
  // flex-direction: column;
}

.order-summary-delivery-container {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.2rem;
}

.order-summary-delivery {
  font-family: DM Sans;
  width: 75%;
  // background-color: red;
}

.deliveryto-text {
  font-weight: bold;
}

.delivery-address {
  color: rgba(15, 25, 26, 0.8);
  font-size: 0.8rem;
}

.change-delivery {
  font-family: DM Sans;
  color: #4361ee;
  font-weight: bold;
}