/** @format */

.apply-coupon-container {
   background: #ffffff;
   width: 100%;
   border: 1px solid rgba(15, 25, 26, 0.25);
   border-radius: 12px;
   padding: 0.7rem;
   display: flex;
   align-items: center;
   justify-content: space-between;
}

.apply-coupon-text {
   margin-left: 0.5rem;
   color: #0f191a;
}

.apply-coupon-wrapper {
   display: inherit;
   flex-direction: inherit;
   align-items: inherit;
}
