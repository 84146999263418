.select-category-main-container {
	min-height: 50px;
	width: 100%;
	border-radius: 10px;
	border: none;
	outline: none;
	display: flex;
	// align-items: center;
	justify-content: space-between;
	background-color: #f3f6f6;
	// background-color: red;
	font-size: 1.2rem;
	font-weight: bold;
	font-family: "DM-Sans", sans-serif;
	padding: 2%;
	overflow-x: auto;
}

.select-category-arrow-btn {
	width: fit-content;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	// background-color: red;
	margin-top: 2px;
	margin-right: 2px;
}

.select-category-placeholder {
	color: #818788;
	font-weight: 400;
	padding-left: 2%;
	// width: 90%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	// background-color: lime;
	flex-grow: 1;
	gap: 0.5rem;
	// min-height: 90%;
	overflow-y: auto;
}

.select-category-placeholder ::-webkit-scrollbar {
	display: none;
}

.select-category-dropdown-container {
	padding: 2%;
	max-height: 200px;
	position: fixed;
	width: 88%;
	z-index: 999;
	margin-top: 2%;
	overflow: scroll;
	display: flex;
	gap: 0.5rem;
	flex-direction: column;
	background-color: #fff;
	border-radius: 10px;
	border: 1px solid #c3c5c6;
}

.select-category-dropdown-container ::-webkit-scrollbar {
	display: none;
}

.select-category-checkbox-wrapper {
	display: flex;
	align-items: center;
	gap: 0.5rem;
	padding: 0 0 0 1%;
	width: 100%;
	height: 12%;
	// background-color: grey;
}

.select-category-checkbox {
	height: 20px;
	width: 20px;
	border-radius: 5px;
}

.select-category-checkbox-label {
	font-size: 1.1rem;
	color: #000;
	font-weight: 500;
}
