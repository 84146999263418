.contact-us-btn-submit {
  background: #0a2540;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  font-size: 16px;
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  background: #0a2540;
  gap: 0.5rem;
  color: #fff;
  padding: 0.1rem 0.75rem;
}

.contact-us-btn-submit-container {
  display: flex;
  justify-content: center;
  padding-left: 15%;
}

.form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.form-group {
  width: 70%;
}

.form-container {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  width: 70%;
}
.form-sub-container {
  width: 100%;
}
@media only screen and (max-width: 900px) {
  .form-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
  }
  .form-sub-container {
    width: 100%;
  }
  .form-group {
    width: 100%;
  }
  .contact-us-btn-submit {
    width: 80%;
  }
  .contact-us-btn-submit-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    margin-bottom: 10%;
  }
}
