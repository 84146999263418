/** @format */

@keyframes goUp {
	0% {
		transform: translateY(100%);
		opacity: 0;
	}
	9%,
	50% {
		transform: translateY(0);
		opacity: 1;
	}
	59% {
		transform: translateY(-100%);
		opacity: 0;
	}
	100% {
		transform: translateY(100%);
		opacity: 0;
	}
}

@keyframes goDown {
	0% {
		transform: translateY(0);
		opacity: 1;
	}
	9% {
		transform: translateY(-100%);
		opacity: 0;
	}
	10%,
	50% {
		transform: translateY(100%);
		opacity: 0;
	}
	59%,
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

.HourlyDailyCard-Outer {
	width: 80vw;
	display: flex;
	padding: 0.7rem;
	border-radius: 0.5rem;
	align-items: center;
	justify-content: space-between;
	gap: 0.5rem;
	overflow: hidden;
}

.HourlyDailyCard-Type {
	font-size: 0.8rem;
	padding: 0.2rem 0.5rem;
	transform: skew(-20deg, 0deg);
	display: inline-block;
	border-radius: 0.3rem;
}

.HourlyDailyCard-Title {
	font-size: 1.2rem;
	font-weight: 600;
	margin: 0.5rem 0;
}

.HourlyDailyCard-ExtraText {
	font-size: 0.8rem;
	font-weight: 600;
	display: inline-block;
	padding: 0.2rem 0.5rem;
	border-radius: 0.3rem;
}

.HourlyDailyCard-Image-1 {
	position: absolute;
	top: 0;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	transform: translateY(0);
	animation: goDown 10s ease-in-out 5s infinite;

	img {
		height: 5rem;
		width: 5rem;
	}
}
.HourlyDailyCard-Image-2 {
	position: absolute;
	top: 0;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	transform: translateY(100%);
	animation: goUp 10s ease-in-out 5s infinite;

	img {
		height: 5rem;
		width: 5rem;
	}
}

.HourlyDailyCard-Images {
	width: 5rem;
	position: relative;
	height: 100%;
	flex-grow: 1;
}
