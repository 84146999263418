.filled-tag-wrapper {
  font-family: DM Sans Bold, sans-serif;
  padding: 4px 8px;
  font-size: 0.75rem;
  margin-top: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 1rem;
  width: fit-content;
  font-weight: 500;

}
.order-and-payment-status-wrapper {

  display: flex;
  flex-direction: column;
  align-items: flex-end;
}