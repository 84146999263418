/** @format */

.toggle-chips-with-switch {
	border: 1px solid #c4c4c4;
	border-radius: 16px;
	display: flex;
	align-items: center;
	font-size: 0.8rem;
	justify-content: center;
	flex-direction: row;
	padding-left: 5px;
}

.toggle-label-text {
	white-space: nowrap;
}
