/** @format */

@keyframes Pop {
   from {
      bottom: -100%;
   }
   to {
      bottom: 0%;
   }
}

.add-to-cart-footer-container {
   position: fixed;
   bottom: 0;
   left: 0;

   width: calc(100% - 4rem);
   height: 4rem;

   background: #0f6656;
   animation: Pop 1s;
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 0 2rem;
   border-radius: 0 0 1.5rem 1.5rem;
}

.add-to-cart-footer-item-count-wrapper {
   color: #f9ca41;
   font-size: 1.2rem;

   font-weight: 400;
   line-height: 1.33;
   letter-spacing: -0.3px;

   margin-bottom: 0.25rem;
}

.add-to-cart-footer-price-wrapper {
   font-size: 1.5rem;
   font-weight: 700;
   line-height: 1.33;

   letter-spacing: -0.3px;
   color: white;
}

.add-to-cart-footer-btn {
   font-size: 1.5rem;
   font-weight: 700;
   line-height: 1.33;
   letter-spacing: -0.3px;

   color: white;
   cursor: pointer;
}

.add-to-cart-footer-btn-container {
   text-decoration: none;
}
