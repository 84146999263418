.non-veg-tag-wrapper {
    display: flex;
    align-items: center;
}

.non-veg-veg-text {
    margin-left: 0.7rem;
    font-size: 0.75rem;
    color: #2D6B4D;
}

.non-veg-non-veg-text {
    color: #D90429;
    font-size: 0.75rem;
    margin-left: 0.45rem;
}

.non-veg-text{
    margin-left: 0.3rem;
}