.apply-loyalty-modal-container {
    background-color: #fff;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    border-radius: 12px 12px;
    padding: 1.3rem;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.apply-loyalty-modal-title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: baseline;
    height: fit-content;
}

.apply-discount-text {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 1.3rem;
    letter-spacing: -0.3px;
    color: #0F191A;

}

.available-coins-text {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 0.9rem;
    line-height: 20px;
    color: #F98200;
    display: flex;
}

.available-coins {
    font-weight: bold;
}

.round-close-icon{
    position: absolute;
    top: -4rem;
}

.divider {
    width: 100vw;
    height: 1px;
    background-color: #E4E4E4;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.coins-and-qty-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 1rem;
    // background-color: grey;
    // height: 7rem;
}

.thousand-coins {
    width: 4rem;
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.5rem;
    display: flex;
    align-items: center;
    letter-spacing: -0.3px;
    margin-top: 0.3rem;
}

.x {
    width: 2rem;
    margin-top: 0.3rem;
}

.insufficient-coins-text {
    color: #D90429;
    margin-top: 0.4rem;
    margin-left: 6rem;
}

.coins-qty-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    
}

.or-text {
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: rgba(15, 25, 26, 0.5);
}

.use-all-coins {
    color: #4361EE;
    font-weight: 500;
    font-size: 0.9rem;
    line-height: 20px;
    margin-top: 0.5rem;
    background: rgba(67, 97, 238, 0.04);
    padding-inline: 1rem;
    padding-block: 0.5rem;
    border-radius: 8px;
    border: 1px solid #4361EE;
    font-size: 0.9rem;
    font-weight: 500;
}

.discount-details-container {
    width: 100%;
    margin-top: 2.2rem;
}

.discount-detail-row {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
}

.discount-detail-key {
    font-size: 1.3rem;
    color: rgba(15, 25, 26, 0.5);
    font-weight: 700;
    display: flex;
    align-items: baseline;
}

.discount-detail-value {
    color: #0F191A;
    font-size: 1.3rem;
    font-weight: 500;
}

.using-coins-text {
    font-size: 0.9rem;
    margin-left: 0.5rem;
    color: rgba(15, 25, 26, 0.5);
    font-weight: 100;
}