/** @format */

.counter-card-counts {
   display: flex;
   align-items: center;
   justify-content: center;
   font-size: 1.2rem;
   font-weight: bold;
   color: #000;
   width: 1rem;
   font-family: "DM Sans", sans-serif;
}
.counter-card-btn {
   color: #0f6656;
   width: 25px;
   font-family: "DM Sans", sans-serif;
   align-items: center;
   display: flex;
   font-weight: bold;
   justify-content: center;
   font-size: 1.2rem;
}
.counter-card-counter {
   display: flex;
   gap: 0.8rem;
   background-color: #fbf6f8;
   align-items: center;
   border: 1px solid #0f6656;
   border-radius: 5px;
   justify-content: center;
   width: 6rem;
   height: 30px;
}
.counter-card-name {
   font-size: 1.1rem;
   font-weight: 500;
   font-family: "DM Sans", sans-serif;
   width: fit-content;
   display: flex;
   align-items: center;
   justify-content: center;
   gap: 0.4rem;
}
.counter-card-main-container {
   padding: 2% 3% 2% 0;
   width: 100%;
   display: flex;
   justify-content: space-between;
   // background-color: grey;
}
.counter-card-checkbox {
   height: 20px;
   width: 20px;
   border-radius: 5px;
}
