/** @format */

.food-item-before-order-container {
   display: flex;
   gap: 0.75rem;
   min-height: 5rem;
   background: #fff;
   flex-direction: row;
   transition: all 1s;
   margin-top: 0.5rem;
   align-items: center;
}

.available-items {
   font-family: DM Sans;
   color: green;
   font-size: 0.85rem;
}

.food-item-before-order-left-section {
   height: 5rem;
   width: 5rem;
}
.food-item-people-ordered-info {
   font-size: 0.7rem;
   color: #2d6b4d;
}
.food-item-before-order-discount-text-content {
   position: absolute;
   top: 5;
   color: #ffffff;
   font-size: 12;
   left: 6;
}

.food-item-before-order-right-section {
   width: calc(100% - 105px);
   height: 100%;
   display: flex;
   flex-direction: column;
   justify-content: space-around;
}

.food-item-before-order-right-top-section {
   display: flex;
   justify-content: space-between;
   margin-top: 1%;
}

.food-item-before-order-right-middle-section {
   display: flex;
   flex-wrap: wrap;
   align-items: flex-start;
   gap: 0.5rem;
   justify-content: space-between;
   align-items: flex-start;
   // background-color: grey;
   flex-direction: column;
}

.food-item-before-order-info-container {
   font-size: 14.56px;
   width: 100%;
}

.food-item-before-order-description {
   width: 75%;
}

.food-item-before-order-see-more-btn {
   font-size: 0.75rem;
   white-space: nowrap;
   font-weight: 500;
}

.food-item-before-order-image-wrapper {
   height: 5rem;
   width: 100%;
   border-radius: 0.5rem;
}

.food-item-before-order-title-wrapper {
   width: 75%;
}

.food-item-before-order-price-wrapper {
   display: flex;
   gap: 0.2rem;
   margin-left: 3%;
}

.food-item-before-order-quantity-btn {
   height: 100%;
   width: 4rem;
   background: #f9ca41;
   border: none;
   border-radius: 10px;
   text-align: center;
}

.food-item-before-order-btns-container {
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin: 0.5rem auto 0.5rem auto;
   width: 65%;
   background: #f9ca411a;
   height: fit-content;
}
.category-name-on-card {
   font-size: 0.8rem;
   color: #ffffff;
   font-family: "DM Sans";
   background-color: rgba(65, 132, 204, 0.7);
   padding-inline: 0.8rem;
   padding-block: 0.2rem;
   border-radius: 4px;
}

.food-item-before-order-quantity-text {
   font-size: 1.75rem;
   font-weight: 700;
   line-height: 1.33;
}

.food-item-before-order-dish-name-container {
   display: flex;
   align-items: center;
   justify-content: center;
   height: 100%;
   background-color: #87b3ab;
   width: 100%;
   color: #0f6656;
   font-size: 3rem;
   border-radius: 10px;
}
.drawer-menu-icon-wrapper {
   color: #0f6656;
   padding: 0.5rem;
}
.food-item-before-order-btns-discount-container {
   position: absolute;
   left: 2;
}

.food-item-before-order-price-content1 {
   margin-right: 0.3rem;
   font-size: 1rem;
   color: #0f191a;
   font-weight: 600;
   font-family: "Open Sans";
}

.food-item-before-order-price-content2 {
   text-decoration: line-through;
   color: rgba(15, 25, 26, 0.5);
   margin-top: 3%;
   font-size: 0.7rem;
   color: #0f191a;
   line-height: 1.33;
   font-weight: 300;
}

.food-item-before-order-out-of-stock-text {
   font-size: 0.9rem;
   color: #d90429;
   font-weight: bold;
   white-space: nowrap;
}

.food-item-before-order-info-wrapper {
   overflow: hidden;
   text-overflow: ellipsis;
   font-size: 0.75rem;

   color: rgba(15, 25, 26, 0.5);

   transition: all 0.5s;
   display: flex;
   justify-content: space-between;
}

.food-item-before-order-dish-name-content {
   letter-spacing: -0.3px;
   font-size: 1rem;
   font-family: "DM Sans";
   font-weight: 600;
   color: #0f191a;
}

.food-item-before-order-count-content {
   font-family: "DM Sans";
   font-style: normal;
   font-weight: 700;
   font-size: 15.6px;
   line-height: 20px;
   text-align: center;
   letter-spacing: -0.3px;
   color: #0f6656;
   display: flex;
   justify-content: flex-end;
}

.food-item-before-order-count-container {
   // background: rgba(230, 57, 70, 0.1);
   border: 1.5px solid #0f6656;
   border-radius: 0.5rem;
   display: flex;
   align-items: center;
   color: #0f6656;
   padding: 0.5rem 0.5rem;
   gap: 0.7rem;
}
.food-item-before-order-add-button {
   border: 1px solid #87b3ab;
   padding: 0.5rem 2rem;
   border-radius: 0.5rem;
   color: #0f6656;
}
.addons-modal {
   display: flex;
   flex-direction: column;
   justify-content: end;
   align-items: center;
   gap: 1rem;
   backdrop-filter: brightness(0.9);
   height: 100vh;
   width: 100%;
   position: fixed;
   top: 0;
   left: 0;
}
.addons-close {
   background-color: white;
   aspect-ratio: 1/1;
   border-radius: 999px;
   padding: 0.5rem;
   rotate: 45deg;
}
.addons-modal-content {
   background-color: white;
   padding: 0.5rem;
   align-self: stretch;
   border-radius: 1rem;
   z-index: 10;
   height: 80%;
   opacity: 1;
}
.addons-modal-item {
   padding: 0.5rem;
   display: flex;
   align-items: center;
}
.addons-item-icon {
   width: 5rem;
   aspect-ratio: 1/1;
   background-color: #87b3ab;
   color: #0f6656;
   text-transform: capitalize;
   display: flex;
   justify-content: center;
   align-items: center;
   font-weight: bold;
   border-radius: 0.5rem;
   font-size: 1.5rem;
}
.addons-img {
   width: 5rem;
   aspect-ratio: 1/1;
}
.addons-item {
   padding: 0.5rem;
   margin-right: auto;
}
.addons-item-name {
   font-weight: bold;
   margin-bottom: 0.5rem;
}
.addons-btns {
   margin-left: auto;
   display: flex;
   align-items: center;
}
.yellow-btn {
   background-color: #f9ca41;
   border-radius: 0.5rem;
   aspect-ratio: 1/1;
   padding: 0px 0.5rem;
   display: flex;
   justify-content: center;
   align-items: center;
}
.addons-count {
   padding: 1.5rem;
   background-color: #fefaec;
}
.addons-head {
   font-weight: bold;
   font-size: 1.3rem;
}
.addons-addons {
   display: flex;
   flex-direction: column;
   gap: 1rem;
   padding: 1rem 0.5rem;
   border: 1px solid #0f191a40;
   border-width: 1px 0px 1px 0px;
}
.addons-addon {
   display: flex;
   justify-content: flex-end;
   align-items: center;
   gap: 1rem;

   :first-child {
      margin-right: auto;
   }
}
.addons-customizations {
   display: flex;
   padding: 1rem 0.5rem;
   flex-direction: column;
   gap: 0.5rem;
}
.addons-customization {
   border-radius: 0.5rem;
   display: flex;
   flex-direction: column;
   gap: 0.5rem;
}
.addons-customization-top {
   display: flex;
   justify-content: space-between;
   align-items: center;
}
.addons-customization-title {
   color: #0f191a;
   font-weight: bold;
   font-size: 1.2rem;
}
.clear-selection {
   color: #0f6656;
   font-size: 1rem;
   cursor: pointer;
}

.other-customization-input {
   border-radius: 8px;
   border: 1px solid rgba(0, 0, 0, 0.5);
   padding: 0.5rem;
   font-family: "DM Sans";
   font-size: 1rem;
   min-height: 0.5rem;
}
