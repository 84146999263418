.dine-in-card-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 100%;
  margin: 0 auto;
  padding: 3%;
}
.dine-in-order-card-main-container {
  border-radius: 8px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border: 1px solid #c3c5c6;
  border-top: 0px;
  width: 90%;
  margin-bottom: 5%;
}
.dine-in-card-item-price {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}
.single-order-items-container {
  border: 1px solid #c3c5c6;
  border-radius: 8px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  display: flex;
  width: 90%;
  align-items: center;
  justify-content: space-between;
  padding: 2%;
}
.order-no {
  font-weight: 600;
  font-size: 1.1rem;
}
.dine-in-card-price {
  font-weight: 600;
  color: #878c8c;
  font-size: 0.9rem;
}
.dine-in-card-item-name {
  font-weight: 600;
  font-size: 1rem;
  color: #000;
}
.dine-in-card-qty {
  font-weight: 500;
  font-size: 1rem;
  > :nth-child(2) {
    font-weight: 600;
  }
}
