.order-details-container2 {
  position: relative;
}

.order-details-order-container {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 6rem;
  padding-top: 5rem;
  overflow-y: scroll;
    height: 100vh;
    &::-webkit-scrollbar{
      display: none;
    }
}

.order-details-skeleton-container {
  display: flex;
  flex-direction: column;
  margin: 1rem 1.5rem 0 1.5rem;
  width: 90%;
}

.order-details-skeleton-subcontainer {
  display: grid;
  grid-template-columns: 1fr 4fr;
}