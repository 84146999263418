/** @format */

.menupage-header {
	display: flex;
	flex-direction: column;
	padding: 0.7rem;
}

.menupage-header-text {
	width: 100%;
}

.menupage-main-container {
	position: relative;
}

.menupage-container {
	width: 100%;
	padding-top: 1rem;

	margin-bottom: 7rem;
	height: 100vh;
	overflow: scroll;

	&::-webkit-scrollbar {
		display: none;
	}
}

.menupage-items-container {
	display: flex;
	flex-direction: column;
	gap: 1rem;

	width: 100%;

	margin-top: 0.25rem;
}

.menupage-items-container2 {
	margin-top: 1rem;
	padding-bottom: 5rem;
}

.menupage-filter-btns-subcontainer {
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: space-between;
}

.menupage-toogle-btn-container {
	font-weight: 500;
	margin-right: -0.35rem;
	// margin-top: 1rem;
	// margin-bottom: 0.5rem;
}

button:focus {
	outline: none;
}
.menupage-most-ordered-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.menupage-most-ordered-subcontainer {
	width: 180px;
	height: 180px;
}
.menupage-most-ordered-img {
	width: 100%;
	height: 100%;
}
.menupage-most-ordered-text-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	color: #868b8c;
	font-size: 1rem;
	font-weight: 400;
	font-family: "DM Sans", sans-serif;
}
