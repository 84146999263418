/** @format */

.menu-card-container {
    margin-top: 0.5rem;
    margin-bottom: 2.5rem;
}

.menu-card-item-container {
    height: 5rem;
    border-radius: 0.5rem;
    box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.105);
    padding: 0 5% 0 5%;
    display: flex;
    text-decoration: none;
    align-items: center;
    justify-content: space-between;
    font-size: 1.1rem;
    color: #0f191a;
    font-family: "DM Sans", sans-serif;
    font-weight: 700;
}

.menu-card-item-arrow-wrapper {
    background-color: #fff;
    height: fit-content;
    width: max-content;
    display: flex;
    align-items: center;
    float: right;
    justify-content: center;
    padding: 1% 4%;
    border-radius: 200px;
}

.menu-card-subcontainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.menu-card-menu-text {
    font-size: 1.5rem;
    color: #0f6656;
    font-weight: bold;
    line-height: 1.33;

    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.suprise-me-btn {
    border: none;
    color: #0f6656;
    background-color: #fff;
    border: 1px solid #0f6656;
    font-size: 1.1rem;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.3rem;
    padding: 1.5% 2% 1.5% 2%;
}

.menu-card-menu-item {
    text-decoration: none;
}

.menu-card-skeleton {
    border-radius: 0.5rem;
    margin-top: 1rem;
}