/** @format */

.order-confirmation-modal-container {
	padding: 1rem;
	position: fixed;
	background: #fff;
	border-radius: 1rem;
	bottom: 0;
	right: 0;
	left: 0;
	justify-content: center;
	display: flex;
	flex-direction: column;
}

.order-confirm-option {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 90%;
	margin-left: 0.7rem;
	padding: 0.4rem;
	border-radius: 8px;
}

.order-option-text {
	font-family: DM Sans;

	font-size: 1.2rem;
	margin-left: 5rem;
}

.order-confirmation-modal-header {
	font-size: 1rem;
	font-weight: 700;
	text-align: center;
	margin-bottom: 5%;
	width: 100%;
	font-family: "DM Sans", sans-serif;
	display: flex;
	justify-content: center;
	align-items: center;
}

.order-confirmation-modal-text {
	font-size: 1rem;
	color: rgba(15, 25, 26, 0.5);
	text-align: center;
	font-family: "DM Sans";
	width: 90%;
	margin: auto;
	// margin-bottom: 5%;
	// margin-top: 1%;
}

.order-confirmation-modal-x {
	position: absolute;
	right: 6%;
}

.order-confirmation-modal-btn-container {
	margin-top: 10%;
	display: flex;
	align-items: center;
	padding-right: 3%;
	justify-content: center;
}

.order-confirm-modal-button {
	background-color: #0f6656;
	font-size: 1.2rem;
	border-radius: 8px;
	font-family: DM Sans;
	color: #fff;
	text-align: center;
	padding: 1rem;
	margin-top: 2rem;
	// position: fixed;

	bottom: 10px;
}

.close-button {
	@extend .order-confirm-modal-button;
	margin-top: 0.5rem;
	background-color: #ffffff;
	color: #000000;
	border-width: 1px;
	border-color: black;
	border-style: solid;
}
