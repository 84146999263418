.page-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1.5rem;
    position: fixed;
        right: 0;
        left: 0;
        background: linear-gradient(90deg, #ffb356 -25%, #fff95b 106.97%);
            z-index: 2000;
}

.page-header-text {
    width: calc(100% - 6rem);
    font-size: 1.25rem;
    line-height: 1.33;
    text-align: center;
    font-weight: 700;
}