/** @format */

.choose-address-page-container {
   display: flex;
   flex-direction: column;
   justify-content: space-between;

   background-color: #0f6656;

   min-height: calc(100vh - 106px);

   padding-right: 0.75rem;
   padding-left: 0.75rem;
   padding-bottom: 1.25rem;
}

.choose-address-page-text-container {
   display: flex;

   align-items: center;
   gap: 0.5rem;
}

.choose-address-page-line {
   height: 1px;
   background: white;
   width: 100%;
}

.choose-address-page-add-new-address-container {
   display: flex;
   justify-content: flex-end;
}

.choose-address-page-add-new-detaile-btn {
   display: flex;
   align-items: center;

   background: #0f6656;
   border: none;
   font-size: 1.5rem;

   line-height: 2rem;
   font-weight: 400;

   padding: 0;
   margin-top: 2rem;

   color: white;
}

.choose-address-page-text1 {
   font-size: 2rem;
   font-weight: 700;
   color: white;
   margin-bottom: 2.5rem;
}

.choose-address-page-text2 {
   font-size: 1.2rem;
   font-weight: 700;
   line-height: 1.33;
   color: white;
   text-align: center;
   margin-bottom: 0.5rem;
   text-decoration-line: underline;
}

.choose-address-page-text3 {
   font-size: 1rem;
   font-weight: 400;
   line-height: 1.33;
   color: white;
   text-align: center;
   margin-bottom: 1.5rem;
}
