/** @format */

.login-container {
   background: #0f6656;
   padding: 1rem;
   padding-top: 0.5rem;
   height: 100vh;
}
input[type="number"] {
   -moz-appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
   margin: 0;
   display: none;
}

.login-main-container {
   // background-color: grey;
   display: flex;
   height: 60vh;
   flex-direction: column;
   justify-content: center;
}

.login-header {
   padding: 0.5rem;
   // background-color: red;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   margin-top: 1rem;
   margin-bottom: 10%;
}

.login-input-container {
   display: flex;
   flex-direction: row;
   margin-top: 1rem;
   // background-color: lime;
   align-items: center;
}

.inputs-container {
   // background-color: grey;
   display: flex;
   flex-direction: column;
}
.login-checkbox-container {
   display: flex;
   align-items: center;
   gap: 0.5rem;

   padding-left: 0.5rem;

   color: white;
   margin-top: 1rem;
}

.login-btn-proceed {
   bottom: 15px;
   position: fixed;
   margin-top: 2rem;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   gap: 10%;
   height: 15%;
   width: 90%;
}

.login-btn {
   background: white;
   color: #0f6656;
   height: 4rem;
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   font-family: "DM Sans Bold", sans-serif;
   flex-direction: row;
   cursor: pointer;
   border-radius: 0.75rem;
   font-size: 1.5rem;
   border: none;
}
.login-terms-and-conditions {
   color: #fff;
   font-size: 14.5px;
   white-space: nowrap;
   font-weight: 400;
}
.text-underline {
   text-decoration-line: underline;
   margin-left: 2%;
   font-family: "DM Sans";
   font-weight: 400;
   color: #fff;

   &:hover {
      color: #fff;
   }
}
.login-note-text {
   font-size: 1rem;
   font-weight: 700;
   line-height: 1.25;
   color: white;
   text-align: center;
   margin-bottom: 0.5rem;
   margin-top: 1rem;
   text-decoration-line: underline;
}

.login-note-content {
   font-size: 0.8rem;
   font-weight: 400;
   line-height: 1.4;
   margin-top: 5%;
   text-align: center;
   color: white;
   margin-bottom: 1rem;
   // background-color: grey;
   width: 100%;
}

.login-text-container {
   margin-top: 0rem;
}

.login-text2 {
   font-size: 1.75rem;
   color: white;
   font-weight: 700;
   line-height: 1.33;
}

.login-text3 {
   font-size: 1.75rem;
   color: white;
   font-weight: 500;
   text-align: center;
   line-height: 1.33;
   font-family: DM Sans;
}

.login-btn-container {
   width: 100%;
   display: flex;
   justify-content: center;
   margin-top: 1rem;
}
