/** @format */

.view-cart-btn-container {
	position: fixed;
	bottom: 0.5rem;
	left: 0.5rem;
	width: calc(100% - 1rem);
	height: 4.2rem;
	border-radius: 12px;
	background: #0f6656;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 2rem;
	z-index: 0;
}

.view-cart-item-count-wrapper {
	color: #fff;
	font-size: 1rem;

	font-weight: 400;
	line-height: 1.33;
	letter-spacing: -0.3px;

	margin-bottom: 0.25rem;
}

.view-cart-price-wrapper {
	font-size: 1.5rem;
	font-weight: 700;
	line-height: 1.33;

	letter-spacing: -0.3px;
	color: white;
}

.view-cart-btn {
	font-size: 1.5rem;
	font-weight: 700;
	letter-spacing: -0.3px;
	color: white;
	cursor: pointer;
	border: none;
	font-family: "DM Sans";
}

.view-cart-yellow-arrow-icon {
	text-decoration: none;
}
