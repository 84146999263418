/** @format */
.test {
   position: relative;
}
.test:after {
   content: "";
   display: inline-block;
   position: absolute;
   width: 100%;
   height: 100%;
   z-index: -1; /* Place the pseudo element right under the content */
   top: 0;
   left: 0;
   right: 0;
   background: linear-gradient(to top, yellow 50%, transparent 50%);
}
