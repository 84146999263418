.selected-category-chip {
  display: flex;
  background-color: #f9ca41;
  padding: 1% 2% 1% 2%;
  color: #000;
  width: min-content;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  font-weight: 500;
  font-family: 'DM Sans', sans-serif;
  border-radius: 5px;
  white-space: nowrap;
}
