/** @format */

.order-details-container {
	margin: 0 auto;
	padding: 1rem;
	border-radius: 12px;
	box-shadow: 0px 2px 12px rgb(25 57 72 / 20%);
	width: calc(90% - 2rem);
	box-sizing: unset;
}

.order-details-container-text {
	display: flex;
	justify-content: space-between;
	margin-top: 0.6rem;
	align-items: flex-start;
}

.order-details-dotted-divider {
	border: 1px dashed rgba(15, 25, 26, 0.1);
	margin-top: 1rem;
	margin-bottom: 0.5rem;
}

.order-details-text {
	text-align: start;
	font-size: 0.8rem;
	color: rgba(15, 25, 26, 0.5);
	font-weight: 700;
	font-family: DM Sans;
}

.order-details-content {
	font-size: 0.8rem;
	color: #0f191a;
	font-weight: bold;
	font-family: DM Sans;
}

.change-order-type {
	font-weight: 100;
	text-decoration-line: underline;
	margin-left: 0.5rem;
	color: rgba(67, 97, 238, 1);
}

.order-details-content-grand-total {
	font-size: 0.8rem;
	color: #0f191a;
	font-weight: bold;
	font-family: DM Sans;
}

.order-details-text-grand-total {
	font-size: 0.9rem;
	color: #0f191a;
	font-weight: 700;
	font-family: DM Sans;
}

.order-details-content-grand-total {
	font-size: 0.9rem;
	color: #000000;
	font-weight: bold;
	font-family: DM Sans;
}

.order-message-overlay {
	background-color: rgba(0, 0, 0, 0.5);
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 100;
	justify-content: center;
	align-items: center;
	display: flex;
}

.order-message-modal {
	background-color: #fff;
	border-radius: 12px;
	padding: 2rem;
	width: 80%;
	height: 40%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	text-align: left;
	font-size: 1.1rem;
	position: relative;
}
