/** @format */

.EmptyCartContainer {
   display: flex;
   align-items: center;
   padding: 10%;
   gap: 0.5rem;
   flex-direction: column;
   justify-content: center;
}
.EmptyCartWrapper {
   width: 70%;
}
.EmptyCartGif {
   width: 100%;
   height: 100%;
}
.EmptyCartText {
   font-size: 1rem;
   color: #0f6656;
   font-family: "DM Sans", sans-serif;
   font-weight: bold;
}
