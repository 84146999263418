/** @format */

.cart-card-container {
	background: #ffffff;
	border: 1.5px solid rgba(15, 25, 26, 0.1);
	border-radius: 12px;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding-inline: 1rem;
}

.cart-main-item-container {
	@extend .cart-card-container;
	flex-direction: row;
	border: 0px;
	background-color: transparent;
	margin-block: 1rem;
	padding-inline: 0rem;
}

.cart-card-accordion {
	padding: 0rem;
	margin: 0rem;
	border: 0px;
}

.cart-item-name {
	font-weight: bold;
	font-size: 15.6px;
}

.cart-item-price {
	margin-top: 0.5rem;
	font-size: 15.6px;
}

.cart-item-sub-total-price {
	text-align: right;
	margin-top: 0.5rem;
	font-style: normal;
	font-weight: 500;
	font-size: 15.6px;
}

.add-on-items-text {
	color: #0f6656;
	font-family: "DM Sans";
	font-size: 14px;
	font-weight: bold;
}
