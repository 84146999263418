/** @format */

.homepage-container {
	width: 100%;
	max-width: 40rem;
	margin: 0 auto;
	overflow: scroll;
	height: 100vh;
	background-repeat: no-repeat;
	background-size: contain;
	&::-webkit-scrollbar {
		display: none;
	}
}
.homepage-top-section {
	// background-image: url("../../asset/svg/BGYellow.svg");
	width: 100%;
	padding: 1rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 1rem;
}

.homepage-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-top: 10px;
	flex-direction: row;
}

.homepage-contact-icon {
	color: #000000;
	text-decoration: none;
	align-items: center;
}

.eatery-text {
	font-family: DM Sans;
	font-style: normal;
	font-weight: 500;
	font-size: 1.1rem;
	line-height: 1.33;
	display: flex;
	align-items: center;
	text-align: center;
	letter-spacing: -0.3px;
	color: red;
	margin-left: 0.5rem;
	margin-bottom: 1rem;
}

.refer {
	font-family: DM Sans;
	font-size: 1.1rem;
	color: #000000;
	display: flex;
	// margin-left: 0.5rem;
}

.refer-container {
	// position: absolute;

	border-radius: 0.5rem;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	padding: 1rem;
	background: linear-gradient(90deg, #ffb356 -25%, #fff95b 106.97%);
	margin-bottom: 1rem;
	align-items: center;
}

.refer-container-1 {
	display: flex;
	flex-direction: row;
	align-self: center;
}

.toggle-chips-row {
	// background-color: grey;
	display: flex;
	flex-direction: row;
	margin-top: 1rem;
	margin-bottom: 1rem;
	width: 100%;
	overflow: hidden;
	overflow-x: scroll;

	&::-webkit-scrollbar {
		display: none;
	}
}

.homepage-not-found {
	// background-color: red;
	height: 100%;
	width: 100%;
}

.homepage-header-text {
	width: 10rem;

	text-align: center;
}

.recommended-chip {
	margin-right: 1rem;
}

.bestSelling-chip {
	margin-right: 1rem;
}

.editing-text {
	color: red;
	text-align: center;
	margin-top: 15;
	font-size: 20;
	font-weight: bold;
}

.homepage-no-items-found {
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 80vh;
	color: #0f6656;
	font-size: 1.5rem;
}

.homepage-food-item {
	margin-top: 1rem;
	padding-bottom: 5rem;
}

.homepage-footer-text {
	text-decoration: underline;
	color: skyblue;
	margin-left: 6px;
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 12.5px;
	line-height: 16px;
	display: flex;
	align-items: center;
	text-align: center;
	letter-spacing: -0.3px;
}

.homepage-order-summary {
	text-decoration: none;
}

.homepage-powered-by-text {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 12.5px;
	line-height: 16px;
	display: flex;
	align-items: center;
	text-align: center;
	letter-spacing: -0.3px;
	justify-content: center;
	color: #0f6656;
	margin-top: 1rem;
}

.homepage-text1 {
	font-size: 1.4rem;
	line-height: 1.33;
	font-weight: bold;
	font-family: "TWKEverett", sans-serif;
	text-align: center;
}

.homepage-text2 {
	font-size: 1.5rem;
	color: #0f6656;
	font-weight: bold;
	line-height: 1.33;
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.homepage-login-btn {
	background: #ffffff;
	border: 0px solid #0f6656;
	border-radius: 4px;
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 15.6px;
	line-height: 20px;
	display: flex;
	align-items: center;
	text-align: center;
	letter-spacing: -0.3px;
	color: #0f6656;
}

.homepage-login-btn-container {
	display: flex;
	justify-content: center;
	margin-bottom: 1rem;
	margin-top: 1rem;
}

.homepage-editing-text {
	// background-color:grey;
	// text-align: center;
	font-family: DM Sans;
	color: #ffffff;
	// ♣color: #4361EE;
	font-size: 1.1rem;
	white-space: nowrap;
	font-weight: 500;
	text-align: center;
}

.addMoreItemContainer {
	display: flex;
	margin-top: 1rem;
	background-color: rgb(65, 132, 204);
	margin-bottom: 1.3rem;
	flex-direction: row;
	align-items: center;
	padding: 0.8rem;
	border-radius: 8px;
	justify-content: space-between;
}

.cancel-btn {
	color: rgb(65, 132, 204);
	background-color: #ffffff;
	padding-inline: 0.4rem;
	padding-block: 0.4rem;
	border-radius: 8px;
}
.drawer-icon-wrapper {
	border: 1.25px solid #e8ebed;
	padding: 3%;
	width: 50px;
	border-radius: 50px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #fff;
}

.HourlyDailyCard-Container {
	display: flex;
	gap: 0.5rem;
	width: max-content;
	margin: 0.5rem 0;
}

.HourlyDailyCard-Wrapper {
	width: 100%;
	overflow: scroll;

	&::-webkit-scrollbar {
		display: none;
	}
}
