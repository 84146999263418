/** @format */

.select-address-card-container {
   display: flex;
   padding: 0.75rem;
   margin-top: 1rem;
   border-radius: 0.75rem;
   background-color: white;
}

.select-address-card-red-line {
   width: 0.5rem;
   margin-right: 1rem;
   border-radius: 12px;
   background-color: #0f6656;
}

.select-address-card-properties-container {
   margin-right: 1rem;
}

.select-address-card-text {
   font-size: 1.2rem;
   font-weight: 500;
   color: #0f6656;
}

.select-address-card-text2 {
   font-size: 1.2rem;
   font-weight: 500;
}
