/** @format */

.logo-container {
   display: flex;
   align-items: center;
   justify-content: center;
   margin-left: 0;
   padding: 20px;
   color: #2d6b4d;
   font-weight: 500;
   white-space: nowrap;
}

.logo {
   width: 50px;
   height: 50px;
}
