.become-our-member-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
}

.become-our-member-container {
	background: rgba(255, 200, 13, 0.25);
	border-radius: 24px;
	width: 90%;
	position: relative;
	overflow: hidden;
	padding: 0.8rem;
}

.become-our-member-text {
	font-weight: 700;
	font-family: DM Sans;
	width: fit-content;
	color: #ff4700;
	font-size: x-large;
	margin-top: 0.5rem;
}

.loyalty-cup-container {
	position: absolute;
	bottom: 0;
	right: 0;
}

.grab-text {
	font-weight: 400;
	font-family: DM Sans;
	color: #0f191a;
	width: 83%;
	font-size: small;
}

.subscribe-now-button {
	text-transform: uppercase;
	background-color: #ffffff;
	text-align: left;
	width: fit-content;
	min-width: 100px;
	padding-inline: 12px;
	padding-block: 8px;
	border-radius: 8px;
	color: #ff4700;
	font-weight: 700;
	margin-top: 12px;
	font-size: 0.7rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 3;
}
