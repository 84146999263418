/** @format */

.surprise-me-main-container {
	width: 97%;
	height: 100%;
	padding: 1%;
}
.surprise-me-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 3%;
	font-family: "DM-Sans", sans-serif;
}

.surprise-me-header-text {
	font-weight: 600;
	font-size: 1.2rem;
	font-family: "DM Sans", sans-serif;
}
.surprise-me-header-btn {
	font-family: "DM Sans", sans-serif;
	color: #0f6656;
	font-weight: 500;
}
.surprise-me-info {
	text-align: center;
	width: 90%;
	font-size: 1.2rem;
}
.surprise-me-info-container {
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 600;
	padding: 5% 0 5% 0;
}

.surpise-me-info-btn {
	position: fixed;
	margin-left: 1%;
}
.suprise-me-info-expanded-wrapper {
	position: fixed;
	right: 7%;
	padding: 2%;
	width: 75%;
	display: flex;
	gap: 0.3rem;
	flex-direction: column;
	border-radius: 10px;
	border: 1px solid #c3c5c6;
	background-color: #fff;
	z-index: 9999;
}

.surprise-me-content-title {
	font-weight: 600;
	width: 100%;
	font-size: 1rem;
}
.surprise-me-content-description {
	width: 100%;
	margin-left: 5%;
	padding: 1%;
	font-size: 0.9rem;
}
.surprise-me-random-selection-wrapper {
	margin-top: 1%;
	width: 100%;
	align-items: center;
	justify-content: center;
	display: flex;
	gap: 1rem;
	padding: 1% 8% 1% 8%;
}
.surprise-me-random-selector {
	border-radius: 10px;
	font-weight: 600;
	text-align: center;
	font-size: 0.9rem;
	padding: 3%;
	background-color: #fdfaec;
}

.random-active {
	background-color: #f9ca41;
}
.surprise-me-food-type-toggler {
	display: flex;
	align-items: center;
	justify-content: center;
	color: #0f6656;
	padding: 2.5% 1% 2.5% 1%;
	border: 1px solid #0f6656;
	border-radius: 5px;
	font-size: 1rem;
	text-transform: capitalize;
	font-family: "DM Sans", sans-serif;
	font-weight: 500;
}
.type-active {
	background-color: #0f6656;
	color: #fff;
}
.surprise-me-toggle-wrapper {
	padding: 4%;
}
.surprise-me-food-type-header {
	font-size: 1.1rem;
	padding-left: 2%;
	font-weight: bold;
}
.surprise-me-list-container {
	margin-top: 1%;
	width: 100%;
	justify-content: center;
	flex-direction: column;
	display: flex;
	gap: 1rem;
	padding: 0 6% 0 6%;
}
.surprise-me-list-header {
	font-size: 1.1rem;
	font-weight: 500;
	font-family: "DM Sans", sans-serif;
	display: flex;
	font-weight: bold;
	align-items: center;
	justify-content: space-between;
}
.surprise-me-input-field-wrapper {
	width: 100%;
}
.surprise-me-input-field {
	height: 50px;
	width: 100%;
	border-radius: 5px;
	border: none;
	outline: none;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #f3f6f6;
	font-size: 1.2rem;
	font-weight: bold;
	font-family: "DM-Sans", sans-serif;
	padding: 2%;
}

.surprise-me-btn-wrapper {
	position: fixed;
	bottom: 0;
	justify-content: center;
	display: flex;
	align-items: center;
	width: 100%;
	padding: 2%;
	// background-color: grey;
}

.surprise-me-counters {
	color: #868b8c;
	font-size: 0.9rem;
	font-weight: 500;
}
.surprise-me-count {
	font-size: 0.9rem;
	font-weight: 600;
	color: #000;
}
.surprise-me-selected-categories-container {
	margin-top: 5%;
	width: 100%;
	justify-content: center;
	flex-direction: column;
	display: flex;
	gap: 1rem;
	padding: 0 6% 0 6%;
}
.surprise-me-selected-categories-sub-container {
	display: flex;
	flex-direction: column;
	// height: 13rem;
	// overflow: scroll;
}
.surprise-me-selected-categories-header-wrapper {
	display: flex;
	align-items: center;
	margin-bottom: 3%;
	width: 90%;
	position: sticky;
	top: 0;
	// background-color:red;
	background-color: #fcfeff;
	justify-content: space-between;
}
.surprise-me-selected-categories-header {
	font-size: 1.1rem;
	color: #0f6656;
	font-weight: 600;
	font-family: "DM Sans", sans-serif;
}
.surprise-me-cr-main-container {
	padding: 3%;
}
.surprise-me-cr-btn-wrapper {
	position: fixed;
	bottom: 0;
	justify-content: center;
	display: flex;
	align-items: center;
	display: flex;
	flex-direction: column;
	width: 95%;
	gap: 0.5rem;
	padding: 2%;
}

.surprise-me-cr-items-header {
	font-size: 1.2rem;
	color: #0f6656;
	display: flex;
	align-items: center;
	gap: 0.5rem;
	font-weight: 600;
	font-family: "TWKEverett", sans-serif;
}
.surprise-me-cr-header-items-wrapper {
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 2%;
	gap: 0.2rem;
}
.surprise-me-hr {
	height: 0.5px;
	width: 90%;
	background-color: #0f6656;
}
.header {
	white-space: nowrap;
}
.surprise-me-amount-input {
	width: 100%;
	border: none;
	outline: none;
	height: 100%;
	background: none;
	font-family: "TWKEverett", sans-serif;
	font-size: 1.2rem;
	font-weight: bold;
	text-align: center;
}
