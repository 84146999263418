/** @format */
.checkout-page-btn-wrapper {
   position: fixed;
   width: 100%;
   bottom: 0;
   border: none;
   background-color: #ffffff;
}
.confirmModalContainer {
   width: 100%;
   border-radius: 10px;
   display: flex;
   flex-direction: column;
}
.Offers-dropdown {
   display: flex;
   flex-direction: column;
   padding-left: 2%;
}
@keyframes moveUp {
   from {
      bottom: -100%;
   }
   to {
      bottom: 0;
   }
}

.confirmOrderRow {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   /* margin-top: 0.5rem; */
   margin-bottom: 1rem;
   font-size: 1rem;
   /* background-color: red; */
}

.confirmText {
   font-family: "DM Sans", sans-serif;
   font-size: 1rem;
   color: rgba(15, 25, 26, 0.5);
   font-weight: bold;
}

.orderCount {
   font-family: "DM Sans", sans-serif;
   color: #000000;
   font-weight: bold;
   font-size: 1rem;
}

.divider {
   height: 1px;
   width: 100%;
   background-color: rgba(15, 25, 26, 0.1);
   margin-top: 0.5rem;
   margin-bottom: 1rem;
}

.confirmText2 {
   color: rgba(230, 57, 70, 1);
   font-size: 1rem;
}

.orderTotalAmount {
   color: rgba(67, 97, 238, 1);
   font-size: 1rem;
}
