.loyalty-program-title {
  font-family: DM Sans;
  font-weight: 700;
  font-size: 1.2rem;
  margin-left: 1rem;
}

.loyalty-description {
  margin-top: 2rem;
  font-size: 1.3rem;
  color: #0f191a;
  font-weight: 500;
}

.by-subscribing-text {
  font-size: 12.6px;
  color: #0f191a;
  font-weight: 400;
  margin-top: 0.5rem;
  padding: 0 2rem;
}

.tandc {
  margin-left: 0.3rem;
  color: #4361ee;
}

.trophy {
  width: 17rem;
  height: 10rem;
  object-fit: contain;
}

.loyalty-submit-button-wrapper {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 1rem 2rem;
}
