.offer-banner {
	flex-grow: 1;
	display: flex;
	align-items: stretch;
	gap: 0.5rem;
	scroll-snap-align: center;
	width: 100% !important;
}
.offers-banner-container {
	display: grid;
	grid-template-columns: repeat(10, 100%); // 10 is the number of offers
	overflow-x: scroll;
	scroll-snap-type: x mandatory;
	width: 100%;
	margin: 0.5rem 0;
}
.offer-img-container {
	padding: 0.7rem 1rem;
	display: flex;
	height: 100%;
	justify-content: center;
	align-items: center;
}
.offer-code {
	font-weight: bold;
	color: #0f6656;
}
.offer-description {
	font-weight: bold;
	opacity: 50%;
}
.offer-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 0.3rem;
	padding: 0.5rem 0;
}
.offer-index {
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-left: auto;
	padding: 0 1rem;
	font-weight: bold;
}
.index-dots {
	background-color: #0f6656;
	border-radius: 999px;
	width: 0.3rem;
	height: 0.3rem;
}
.index-dots-container {
	display: flex;
	gap: 0.1rem;
}
