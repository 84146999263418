/** @format */

.surprise-me-count-input {
   text-align: center;
   border: none;
   background-color: #f3f6f6;
   outline: none;
   font-size: 1.2rem;
   font-weight: bold;
   font-family: "DM Sans", sans-serif;
}

.counter-btn-wrapper {
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 0.5rem;
   position: fixed;
   right: 10%;

   .surprise-me-counter-btn {
      display: flex;
      align-items: center;
      color: #0f6656;
      justify-content: center;
      border-radius: 10px;
   }
}

.completely-random-no-of-items {
   font-family: DM Sans;
   font-size: 1.5rem;
   font-weight: bold;
}

.completely-random-icons {
   // background-color: lime;
   min-width: 40%;
   justify-content: space-between;
   display: inherit;
   align-items: center;
}

.completely-random-icons-container {
   // background-color: grey;
   justify-content: center;
   align-items: center;
   display: flex;
   align-self: center;
}
