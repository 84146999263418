.custom-font {
  font-family: "TWK Everett", sans-serif !important;
}
.otp-verification-container {
  // min-height: calc(100vh - 2.5rem);
  height: 100vh;
  background: #e63946;
  padding: 1rem;
  padding-bottom: 1.5rem;
}

input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  margin: 0;
  display: none;
}

.otp-verification-input-container {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 2.5rem;
}

.otp-verification-otp-header {
  padding: 0.5rem;
}

.otp-verification-header {
  // background-color: red;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.otp-text-no {
  font-size: 1rem;
  color: #a4a8a9;
  font-weight: normal;
  line-height: 1.33rem;
  margin-top: 1rem;
}

.otp-text-phone-no {
  @extend .otp-text-no;
  margin-top: 1rem;
  font-size: 1.5rem;
  color: rgb(57, 57, 57);
}

.otp-text-receive-code {
  font-weight: 400;
  font-size: 1rem;
  padding: 0 4px 0 8px;
  line-height: 1.25;
  text-align: center;
  color: #0f191a;
}

.otp-text-resend {
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.25rem;
  margin-top: 1.4rem;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.otp-resend-timer {
  margin-left: 0.5rem;
}

.otp-text-verification {
  font-size: 1.5rem;
  color: white;
  font-weight: 700;
  line-height: 1.33rem;
  margin-top: 1rem;
  font-family: DM Sans;
}

.otp-input {
  border: none;
  height: 3.75rem;
  width: 3.75rem;

  border: 1px solid rgba(255, 255, 255, 0.5);

  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.08) 0%,
    rgba(255, 255, 255, 0.08) 101.15%
  );

  border-radius: 0.75rem;

  color: white;
  font-weight: 500;

  font-size: 1.5rem;
  text-align: center;
}

.otp-input:focus {
  outline: none;
}

.otp-verify-btn-text {
  margin-right: 0.75rem;
}

.otp-verify-btn:disabled {
  opacity: 0.6;
}
