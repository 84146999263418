.address-screen-container {
    padding: 2rem;
}

.address-header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-family: DM Sans;
    font-size: 1.5rem;
}


.address-inputs-container {
    margin-top: 1rem;
        // background-color: grey;
        height: 70vh;
        overflow: hidden;
        overflow-y: scroll;
}

.add-location-button {
    width: 84%;
    background-color: rgba(230, 57, 70, 1);
    padding: 1rem;
    font-family: DM Sans;
    font-size: 1.3rem;
    color: #ffffff;
    border-radius: 8px;
    border-width: 0px;
    position: fixed;
    bottom: 1rem;

}