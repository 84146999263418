.plus-and-minus-container {
	border: 1px solid #87b3ab;
	height: 2rem;
	border-radius: 8px;
	display: flex;
	align-items: center;
	width: 5.5rem;
	flex-direction: row;
	justify-content: space-between;
}

.plus-and-minus-qty-text {
	color: #0f6656;
}
