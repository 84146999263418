/** @format */

.dining-in-confirmation-text-content {
   font-family: DM Sans;
   font-style: normal;
   // font-weight: 700;
   font-size: 19.4px;
   line-height: 25px;
   text-align: center;
   letter-spacing: -0.3px;
   color: #0f191a;
}

.dining-in-confirmation-btn-container {
   border-radius: 12px;
   height: 3rem;
   width: 33%;
   display: flex;
   justify-content: center;
   align-items: center;
   margin: 1rem;
   background: #0f6656;
   color: #fff;
   font-family: DM Sans;
   //   font-weight: bold;
}

.dining-in-confirmation-heading {
   font-family: DM Sans;
   font-style: normal;
   font-weight: bold;
   font-size: 24.4px;
   line-height: 32px;
   letter-spacing: -0.3px;
   color: #0f191a;
   margin-right: 40px;
}

.dining-in-confirmation-container {
   height: calc(100vh - 1rem);
}

.dining-in-confirmation-subcontainer {
   // background-color: grey;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
   padding: 1rem;
}

.dining-in-confirmation-text-content-container {
   display: flex;
   justify-content: space-evenly;
   flex-direction: column;
   align-items: center;
   height: 80vh;
   padding: 1rem;
}

.dining-in-confirmation-text-content-subcontainer {
   display: flex;
   justify-content: center;
   flex-direction: column;
   align-items: center;
}

.pay-now-btn {
   @extend .dining-in-confirmation-btn-container;
   background: #f9ca41;
   color: #000000;
   width: 8rem;
}
