/** @format */

.card {
   position: absolute;
   top: 0;
   left: 0;
   opacity: 0;
   transition: opacity 0.5s ease;
   background-color: transparent;
   border: 0px;
   &.fade-in {
      opacity: 1;
      z-index: 1; // Ensures that the fading in card is on top
   }

   &.fade-out {
      opacity: 0;
      z-index: 0; // Ensures that the fading out card is behind
   }
}
