/** @format */

@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap");

* {
   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Open Sans", "Roboto", "Oxygen", "Ubuntu", "DM Sans", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Poppins", sans-serif;
}

html {
   scroll-behavior: smooth;
}

body {
   margin: 0;

   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   line-height: normal;
   -webkit-touch-callout: none;
   /* iOS Safari */
   -webkit-user-select: none;
   /* Safari */
   -khtml-user-select: none;
   /* Konqueror HTML */
   -moz-user-select: none;
   /* Old versions of Firefox */
   -ms-user-select: none;
   /* Internet Explorer/Edge */
   user-select: none;
}

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
   cursor: pointer;
}

.hide-scroll-bar {
   -ms-overflow-style: none; /* IE and Edge */
   scrollbar-width: none;
}
.hide-scroll-bar::-webkit-scrollbar {
   display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
