.styled-btn {
  height: 3rem;
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: DM Sans;
  flex-direction: row;
  margin: 0 auto;
  cursor: pointer;
  border-radius: 8px;
  font-size: 1.3rem;
  justify-content: center;
  border-width: 0px;
}

.styled-btn:disabled {
  opacity: 0.6;
}

.styled-btn-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  // background-color: grey;
  width: 100%;
  gap: 0.5rem;
  justify-content: center;
}

.styled-btn-container2 {
  width: 70%;
}

.styled-btn-yellow-arrow-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
