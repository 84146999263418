/** @format */

.offers-message-wrapper {
   display: flex;
   align-items: center;
   align-self: center;
   flex-direction: row;
   justify-content: center;
   margin-top: 1rem;
   width: 90%;
}

.offer-message-hurray-icon {
   height: 23px;
   width: 23px;
   margin-right: 3px;
}

.offers-message-container {
   background: rgba(67, 97, 238, 0.1);
   width: 100%;
   padding-inline: 1rem;
   padding-block: 1rem;
   border: 2px dashed #4361ee;
   border-radius: 12px;
   display: flex;
   flex-direction: row;
   color: #4361ee;
   display: flex;
   align-items: center;
   justify-content: center;
   gap: 5px;
}

.offer-bold {
   font-weight: bold;
   display: flex;
   flex-direction: row;
}

.offers-message-row {
   display: flex;
   flex-direction: row;
   gap: 0.2rem;
   white-space: nowrap;
   font-size: 1rem;
   align-items: center;
}
