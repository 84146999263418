/** @format */

.amenities-heading-text {
   font-family: system-ui;
   font-style: normal;
   font-weight: 700;
   font-size: 24.4px;
   line-height: 32px;
   letter-spacing: -0.3px;
   color: #0f6656;
   margin: 1.5rem 0.2rem 0.75rem 0.5rem;
}

.amenities-item-container {
   border: 1.5px solid rgba(15, 25, 26, 0.25);
   border-radius: 12px;
   position: relative;
   margin-bottom: 0.75rem;
}

.amenities-item-name {
   font-family: system-ui;
   font-style: normal;
   font-weight: 700;
   font-size: 24.4px;
   line-height: 32px;
   letter-spacing: -0.3px;
   color: #0f191a;
}

.amenities-quantity {
   font-family: system-ui;
   font-style: normal;
   font-weight: 400;
   font-size: 15.6px;
   line-height: 20px;
   letter-spacing: -0.3px;
   color: rgba(15, 25, 26, 0.5);
   margin-top: 0.5rem;
}

.amenities-container {
   width: 95%;
   margin: 0 auto;
}

.amenities-subcontainer {
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding-top: 10px;
   margin-bottom: 1rem;
   flex-direction: row;
}

.amenities-contact-us-container {
   color: #000;
   text-decoration: none;
   align-items: center;
}

.amenities-restaurant-name {
   width: 10rem;
   text-align: center;
   size: 1.4rem;
   line-height: 1.33;
   font-weight: 700;
   text-align: center;
}

.amenities-plus-minus-btns-text {
   font-size: 1.5rem;
   margin: 0 0.5rem;
}
