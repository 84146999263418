.filter-modal-container {
    padding: 1rem;
}

.filter-modal-container-header {
    display: flex;
    justify-content: center;
    align-items: center;
}

.filter-modal-radio-btn-wrapper {
    display: flex;
    align-items: center;
  
    margin-bottom: 1rem;
} 

.filter-modal-radio-input {
    height: 1.25rem;
    width: 1.25rem;
  
    margin-right: 1.1rem;
}

.filter-modal-radio-input-label {
    font-size: 1.5rem;
    font-weight: 400;
  
    line-height: 1.33;
  
    letter-spacing: -0.3px;
    color: #0f191a;
}

.filter-modal-filter-by-text {
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
}